footer{
	background-color: $color6;
    // border-top: 1px solid $gray-400;
    color: $color1;
    .container{
		padding-top: 2rem;
		padding-bottom: 0;
	}
    img{
        display: block;
        margin-bottom: 1rem;
        width: 12rem;
    }
    a{
        // color: $color1;
        // text-decoration: none;
        &:hover{
            text-decoration: underline;
        }
    }
    @include media-breakpoint-only(xs){
        img{
            display: block;
            margin-bottom: 1rem;
            width: 9rem;
        }
    }
}


