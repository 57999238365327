header{
	background-color: $color6;
	.container{
		padding-top: 2rem;
		padding-bottom: 2rem;
		> div{
			gap: 2rem;
		}
	}
	.logos{
		display: flex;
		flex:1;
		img, svg{
			width: 12rem;
		}
		span{
			display: inline-block;
			&:first-child{
				margin-right: 2rem;
				padding-right: 2rem;
				border-right: 1px solid $color3;
			}
		}
	}
	.teamlead{
		color: #000;
		@include media-breakpoint-up(md) {
			&--info{
				text-align: right;
			}
		}
		&--image{
			img{
				width: inherit;
				max-height: 80px;
				border-radius: 50%;
			}
		}
		a{
			color: #42A2B0;
			&:hover{
				color: $color1;
				text-decoration: underline;
			}
		}
	}
}
@media only print {
    header {
        position: inherit;
    }
	main, #main{
		margin-top: 0 !important;
	}
	.btn{
		display: none;
	}
}

@include media-breakpoint-down(md) {
	header{
		position: static;
		top: 0;
		width: 100%;
		.logos{
			img, svg{
				width: 8rem;
				margin-top: .5rem;
				margin-bottom: .5rem;
			}
			span{
				display: inline-block;
				&:first-child{
					margin-right: 1rem;
					padding-right: 1rem;
					border-right: 1px solid $color3;
				}
			}
		}
		.container{
			padding-top: 1rem;
			padding-bottom: 1rem;
		}
	}
}
@include media-breakpoint-down(sm) {
	header{
		.logos{
			display: inline-block;
			img, svg{
				width: 8rem;
			}
		}
	}
}
@include media-breakpoint-only(xs) {
	header{
		.container{
			> div{
				gap: 1.25rem;
				// align-items: flex-start !important;
			}
		}
		.logos{
			// outline: 1px solid red;
			flex: inherit;
			max-width: 100px;
			span{
				&:first-child{
					margin: 0;
					padding: 0;
					border:none;
					border-bottom: 1px solid $color3;
				}
			}
			img, svg{
				width: 8rem;
			}
		}
		.teamlead{
			&--info{
				> div{
					margin-bottom: .3rem;
				}
			}
			// outline: 1px solid red;
			// font-size: 0.95rem;
		}
		.teamlead--image{
			display: none;
		}
	}
}
