// BUTTONS
.btn{
    border-radius: 0;
    text-decoration: none;
    font-weight: 700;
    color: $color3;
    &:hover{
        border-color: $color5 ;
        background-color: $color5 ;
        color: darken($color3,10%);
    }
    &.btn-lg{
        font-size: inherit;
        padding: .8rem .75rem;
    }
    &:focus{
        outline: none;
        box-shadow: none;
    }
}
.btn-theme{
    border-color: $color3 ;
    background-color: $color3 ;
    color: white ;
    &:hover{
        color: white ;
        border-color: lighten($color3,6%);
        background-color: lighten($color3,6%);
        text-decoration: none !important;
    }
}
@include media-breakpoint-only(xs) {
    .btn-angle-right{
        align-items: initial;
        &:after{
            margin-top: 1px;
        }
    }
}