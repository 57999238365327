
.toast { 
    position: fixed;
    top: 0;
    width: 100% !important;
    background: #E07079;
    margin: 0 !important;
    max-width: none !important;
    border: 0;
    box-shadow: none;
    border-radius: 0;
    color: white;
    font-size: 0.9rem;
    right: 0;
    margin: 1em;
    z-index: 99;
    height: 0;
    overflow: hidden;
    &.show{
        height: inherit;
    }
    .container{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .toast-body{
        display: flex;
        align-items: center;
        button{
            &:focus{
                outline: 0;
            }
        }
        button:before{
            line-height: 0;
            margin-right: .5rem;
            content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNS43MDczIDEuNzA3MTFDMTYuMDk3OCAxLjMxNjU4IDE2LjA5NzggMC42ODM0MTcgMTUuNzA3MyAwLjI5Mjg5M0MxNS4zMTY3IC0wLjA5NzYzMTEgMTQuNjgzNiAtMC4wOTc2MzExIDE0LjI5MyAwLjI5Mjg5M0w4LjAwMDA4IDYuNTg1ODZMMS43MDcxMSAwLjI5Mjg5M0MxLjMxNjU4IC0wLjA5NzYzMTEgMC42ODM0MTggLTAuMDk3NjMxMSAwLjI5Mjg5MyAwLjI5Mjg5M0MtMC4wOTc2MzExIDAuNjgzNDE3IC0wLjA5NzYzMTEgMS4zMTY1OCAwLjI5Mjg5MyAxLjcwNzExTDYuNTg1ODYgOC4wMDAwOEwwLjI5Mjg5MyAxNC4yOTNDLTAuMDk3NjMxMSAxNC42ODM2IC0wLjA5NzYzMTEgMTUuMzE2NyAwLjI5Mjg5MyAxNS43MDczQzAuNjgzNDE4IDE2LjA5NzggMS4zMTY1OCAxNi4wOTc4IDEuNzA3MTEgMTUuNzA3M0w4LjAwMDA4IDkuNDE0MjlMMTQuMjkzIDE1LjcwNzNDMTQuNjgzNiAxNi4wOTc4IDE1LjMxNjcgMTYuMDk3OCAxNS43MDczIDE1LjcwNzNDMTYuMDk3OCAxNS4zMTY3IDE2LjA5NzggMTQuNjgzNiAxNS43MDczIDE0LjI5M0w5LjQxNDI5IDguMDAwMDhMMTUuNzA3MyAxLjcwNzExWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==');

        }
    }
}