label{
    color: $color1;
    font-weight: 700;
    // font-size: 1rem;
    .text-muted{
        font-size: 12px;
        color: $color4 !important;
        font-weight: 400;
    }
}
.form-group{
    margin-bottom: 1.5rem;
}
input[type=text],
input[type=email],
input[type=password],
textarea,
select{
    border: none;
    border-radius: 0 !important;
    border: none !important;
    background-color: $color5 !important;
    padding: .375rem .75rem;
    color: $color1;
    &:focus{
        outline: none; 
        box-shadow: none;
        background-color: #F4F5F4;
        border-bottom: 1px solid $color1 !important;
    }
}
.form-control:focus{
    outline: none; 
    box-shadow: none;
    background-color: #F4F5F4;  
}