.navbar{
    padding: 0;
}
.p-nav {
    flex-direction:column;
    align-items: flex-end;
    &__top{
        display: flex;
        margin-bottom: 1rem;	
        ul {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                margin: 0 0.1rem;
                a {
                    text-decoration: none;
                    padding: 0.5rem;
                    line-height: normal;
                    &:hover {
                        text-decoration: underline;
                        color: $dark;
                    }
                }
                &.active {
                    a {
                        font-weight: bold;
                        // text-decoration: underline;
                    }
                }
            }
        }
    }
    &__lang{
        border-left: 1px solid $gray-500;
        padding-left: .5rem !important;
        margin-left: .5rem !important;
        li{
            &:last-child{
                a{
                    padding-right: 0;
                }
            }
            &.active {
                a {
                    font-weight: bold;
                    // text-decoration: underline;
                }
            }
        }
    }
    &__main {
        display: flex;
        width: 100%;
        ul {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            list-style: none;
            margin: 0 auto 0 0;
            padding: 0;
            li {
                margin: 0 0.1rem;
                a {
                    text-decoration: none;
                    padding: 0.5rem;
                    line-height: normal;
                    &:hover {
                        color: $dark;
                        text-decoration: underline;
                    }
                }
                &.active {
                    a {
                        font-weight: bold;
                        // text-decoration: underline;
                    }
                }
            }
        }
    }
    &__side{
        margin: 0;
        padding: 0;
        list-style: none;
        li{
            margin-bottom: 1rem;
            a{
                &:hover{
                    color: $dark;
                    text-decoration: underline;
                }
            }
            &.active {
                a {
                    font-weight: bold;
                    // text-decoration: underline;
                }
            }
        }
    }
}

@include media-breakpoint-down(md) { 
    .navbar{
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 999;
        justify-content: space-between;
        padding: 1rem 2rem !important;
        background-color: $white;
		box-shadow: 0 10px 10px rgba($gray-500, 0.2);
        .navbar-toggler{
            padding-right: 0;
        }
        .navbar-toggler-icon{
            position: relative;
            background-image: none;
            display: inline-block;
            height: 3px;
            width: 25px;
            background: $color1;
            transition: background 0.3s 0.3s;
            &:before{
                position: absolute;
                display: inline-block;
                height: 3px;
                width: 25px;
                background: $color1;
                top: -8px;
                content: '';
                right: 0;
                transform: translateZ(0);
                backface-visibility: hidden;
                transform-origin: 0% 50%;
                transition: all 0.3s 0.3s, -webkit-transform 0.3s 0.3s;
            }
            &:after{
                position: absolute;
                display: inline-block;
                height: 3px;
                width: 25px;
                background: $color1;
                top: 8px;
                content: '';
                right: 0;
                transform: translateZ(0);
                backface-visibility: hidden;
                transform-origin: 0% 50%;
                transition: all 0.3s 0.3s, -webkit-transform 0.3s 0.3s;
            }
        }
        .navbar-toggler{
            border: 0;
            &:focus{
                outline: none !important;
            }
            &.active{
                .navbar-toggler-icon{
                    background: transparent;
                    &:before{
                        transform: translateX(4px) translateY(-3px) rotate(45deg);
                    }
                    &:after{
                        transform: translateX(4px) translateY(-2px) rotate(-45deg);
                    }
                }
            }
        }
        .navbar-collapse{
            overflow: scroll;
        }
        .p-nav {
            &__top,
            &__main{
                ul{
                    li{
                        margin: 0 0.2rem;
                        &:first-child{
                            margin-left: 0;
                            a{
                                padding-left: 0 !important;
                            }
                        }
                        a{
                            display: inline-block;
                            padding: 0.45rem 0.75rem !important;
                            // background-color: lighten($gray-200,3%);
                            // border:1px dashed rgba($primary, 0.5);
                            // border-radius: .5rem;
                        }
                    }
                }
            }
            &__top{
                margin-top: 2rem;
                margin-bottom: 0.5rem;
                li{
                    &:first-child a{
                        padding-left: 0;
                    }
                }
            }
            &__main{
                margin-bottom: 1rem;
                li{
                    &:first-child a{
                        padding-left: 0;
                    }     
                }
            }
            .form-inline{ display: none;}
        }
        &:focus{
            outline: none !important;
        }
    }
}
@include media-breakpoint-only(xs) {
    .navbar{
        .navbar-brand{
            order: 1;
        }
        .navbar-toggler{
            order: 2;
        }
        > .form-inline{
            order: 3;
            display: flex;
            flex-wrap: nowrap;
        }
        .p-nav {
            order: 4;
            display: flex;
            align-items: center;
            &__top{
                margin-top: 1rem;
                padding-bottom: 1rem;
                border-bottom: 1px solid $gray-400;
                ul{ order: 2;}
                .p-nav__lang{ order: 1;}
            }
            &__main{
                margin-bottom: 1rem;
            }
            &__top,
            &__main{
                flex-direction: column;
                justify-content: center;
                width: 100%;
                ul{
                    flex-direction: column;
                    justify-content: center;
                    margin: 0;
                    li{
                        margin: 0.2rem auto;
                        width: 100%;
                        a{
                            display: inline-block;
                            padding: .45rem 0!important;
                            // padding: 0.5rem 0.75rem !important;
                            // background-color: $gray-200;
                        }
                        &:first-child{
                            margin-left: 0;
                            a{
                                padding-left: 0 !important;
                            }
                        }
                    }
                }
            }
            &__lang{
                display: flex;
                flex-direction: row !important;
                flex-wrap: nowrap;
                border-left: none;
                padding-left: 0 !important;
                margin-left: auto !important;
                li{
                    width: auto !important;
                    margin: 0.2rem !important;
                }
            }
            .form-inline{
                flex-wrap: nowrap;
            }
        }
    }
}