.backTop-container {
	position:fixed;
	z-index:999;
	bottom: 45px;
	height: 40px;
    width: 40px;
    right: 0;
}
#backTop {
	position:absolute;
	z-index:999;
	right:1.5rem;
	width:40px;
	height:40px;
	border-radius: .5rem;
	background:$color2;
	cursor:pointer;
	transition: all .2s ease;
	&:hover{
		// transform: scale( 1.1);
		border-radius: 50%;
		background:darken($color2,6%);
		// border-color: transparent;
	}
	&:before{
		display:flex;
		align-items: center;
		justify-content: center;
		width:40px !important;
		height:40px !important;
		// content: url('../../assets/images/angle-up-white.svg');
		content: 'top';
		line-height: 0;
	}
}
#backTop.masked {
	opacity:0;
	right: -50px;
	cursor: default;
}
// @include media-breakpoint-down(md) {
// 	#backTop{
// 		background:lighten($color2, 6%);
// 	}
// }
/* @include media-breakpoint-only(xs) {
	.backTop-container{
		display: none;
	}
	// #backTop{
	// 	background-color: rgba($white,.7);
	// }
} */