$color1: #00424D; // dark
$color2: #64748B;
$color3: #0099B3;
$color4: #94A3B8; 
$color5: #F1F5F9;
$color6: #F4F5FB;
$color7: #00A590;
// $dark: #00424D !important;

$white: #ffffff;
$light:#F7F7FB; // licht grijs
$dark: #333333; // dark grey

.color1{ color:$color1!important;}
.color2{ color:$color2!important;}
.color3{ color:$color3!important;}
.color4{ color:$color4!important;}
.white{ color:white!important;}

.bgcolor1{ background-image: none!important; background:$color1!important; color:white; h2, h3, a{color:white;}}
.bgcolor2{ background-image: none!important; background:$color2!important; color:white; h2, h3, a{color:white;}; .card{background-color: transparent;}}
.bgcolor3{ background-image: none!important; background:$color2!important; color:white; h2, h3, a{color:white;}; .card{background-color: transparent;}}
.bgcolor4{ background-image: none!important; background:$color4!important; }
.bgwhite{ background-image: none!important; background:white!important; }

img{
	max-width: 100%;
	height: auto;
	image-rendering: -webkit-optimize-contrast;
}
// @include media-breakpoint-up(md) {}
// @include media-breakpoint-up(lg) {}

.well{
	background-color: $color6;
	padding: 1rem;
	font-size: 20px;
}