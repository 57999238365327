.card-deck{
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
    .card{
        margin: 0 1rem;
    }
    &.cards-per-3{
        flex-wrap: wrap;
        .card{    
            flex-basis: 100%;
            max-width: calc(33.33% - 2rem);
            margin-bottom: 0;
        }
    }
    &.cards-per-2{
        flex-wrap: wrap;
        .card{    
            flex-basis: 100%;
            max-width: calc(50% - 2rem);
            margin-bottom: 0;
        }
    }
}
@include media-breakpoint-only(xs) {
    .card-deck{
        margin-left: 0;
        margin-right: 0; 
        flex-wrap: wrap;
        flex-direction: column;
        &.cards-per-3, &.cards-per-2{
            .card{
                margin-bottom: 2rem;
            }
        }
        .card{    
            flex-basis: 100%;
            max-width: none !important;
            margin: 0;
            margin-bottom: 15px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    } 
}

.card{
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    position: relative;
    flex: 1;
    margin-bottom: 1.5rem;
    padding:0;
    border: none;
    // box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    background-color: $gray-200;
    overflow: hidden;
    // background-color: $white;
    transition: background-color 0.5s ease;
    .card-body{
        padding: 2rem;
    }
    .image img{
        transition: all 0.2s ease;
    }
    &:hover{
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
        h2, h3{ color: $color2;}
        .image img{
            transform: scale(1.05);
        }
    }

    .stretched-link{        
        padding: 0 1.5rem 1rem;
        text-decoration: none;
        font-weight: 700;
        color: #575757 ;
        &:after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            pointer-events: auto;
            content: "";
            background-color: transparent;
            order: 2;
        }
        &:before{
            position: relative;
            content: url('../../assets/images/angle-right-grey.svg');
            display: inline-block;
            width: 6px;
            margin-right: 5px;
            margin-bottom: 2px;
            vertical-align: middle;
        }
        &:hover{
            color: $color2;
            text-decoration: none;
            &:before{
                content: url('../../assets/images/angle-right-blue.svg');
            }
        }
    }
    .icon{
        overflow: hidden;
        padding: 1.5rem 0 0 1.5rem;
        img{
            height: 40px;
        }
    }
    .image{
        padding: 0;
        overflow: hidden;
        img{
            border-radius: 15px 15px 0 0;
        }
    }
}
@include media-breakpoint-only(xs) {
    flex-direction: column;
    .card{
        flex-basis: 100%;
        max-width: none !important; 
        margin-bottom: 1.5rem;
    }
}
.card-news{
    .card:hover{
        background-color: $color3;
        color:$white;        
        .card-content{
            h2, h3{ color:$white}
        }
        .stretched-link{
                color: $white;
                text-decoration: none;
                &:before{
                    content: url('../../assets/images/angle-right-white.svg');
                    width: 6px;
                    margin-right: 5px;
                    margin-bottom: 2px;
                }
        }
    }
}
// @include media-breakpoint-up(sm) {
//     &.card--filter{
//         border:none;
//         &:hover{ border:none; }
//         .btn-filter{
//             display: none;
//         }
//         .navbar-filter > div{ padding: 0 !important;}
//     }
// }