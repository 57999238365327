.row_madeby{
	position: relative;
	background-color: $color6;
}
.cntr_madeby{
    position: absolute;
    bottom: 0;
    right: 2rem;
	z-index: 9;
	.badge a{
		opacity:.6;	
		display: block;
		background-image:url(../../assets/images/korazon.badge.png);
		transition: all .1s ease;
		background-size:20px 20px;
		background-repeat: no-repeat;
		background-position: right center;
		margin-bottom: 5px;		
		font-family: sans-serif;
		font-size:16px;
		font-weight:bold;
		text-decoration:none !important;
		color:transparent !important;
	}
	
	.badge a:hover, .badge a:focus, .badge a:active {
		background-size:26px 26px;
		background-position: left center;
		padding:0 0 0 30px;
		color:rgba(#29275F, 0.7) !important;
		opacity:1;
		text-decoration:none !important;
	}
	.badge.right a{
		margin-left: 1%;
	}
}
@include media-breakpoint-only(xs){
	.cntr_madeby{
		position: relative;
		right: inherit;
		padding:30px 15px 0;
		text-align: right;
		.badge a{
			display: inline-block;
			color:rgba(#29275F, 0.7) !important;
			background-position: 0;
			padding-left: 25px;
		}
	}
}