

// PROGRESS BAR DESKTOPS & TABLETS
.progressbar{

  // HORIZONTAL
  &.horizontal{
    .progress{
      background-color: $color4;
      border-radius: 0;
      height: 2px;
      &--labels{
        margin-bottom: -2px;
        color:$color4;
        &.row{
          margin-left: 0;
          margin-right: 0;
          .col{ 
            padding-left: 0; 
            padding-right: 0;
          }
        }
        .done{ 
          display: flex;
          align-items: flex-start;
          border-bottom: 2px solid $color7;
          color: $color7 ;
          font-weight: 700;
          &:before{
            margin-right: .3rem;
            content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMiAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMSAwQzQuOTI1IDAgMCA0LjkyNSAwIDExQzAgMTcuMDc1IDQuOTI1IDIyIDExIDIyQzE3LjA3NSAyMiAyMiAxNy4wNzUgMjIgMTFDMjIgNC45MjUgMTcuMDc1IDAgMTEgMFpNMTUuNzY4IDkuMTRDMTUuODU1OCA5LjAzOTY0IDE1LjkyMjYgOC45MjI3NCAxNS45NjQ2IDguNzk2MTdDMTYuMDA2NSA4LjY2OTYgMTYuMDIyNyA4LjUzNTkxIDE2LjAxMjMgOC40MDI5OEMxNi4wMDE4IDguMjcwMDUgMTUuOTY0OCA4LjE0MDU2IDE1LjkwMzYgOC4wMjIxM0MxNS44NDIzIDcuOTAzNjkgMTUuNzU4IDcuNzk4NzEgMTUuNjU1NSA3LjcxMzM0QzE1LjU1MzEgNy42Mjc5OCAxNS40MzQ2IDcuNTYzOTYgMTUuMzA3MSA3LjUyNTA2QzE1LjE3OTYgNy40ODYxNiAxNS4wNDU1IDcuNDczMTYgMTQuOTEyOSA3LjQ4NjgzQzE0Ljc4MDIgNy41MDA0OSAxNC42NTE3IDcuNTQwNTUgMTQuNTM0NyA3LjYwNDYzQzE0LjQxNzggNy42Njg3MiAxNC4zMTQ5IDcuNzU1NTQgMTQuMjMyIDcuODZMOS45MzIgMTMuMDE5TDcuNzA3IDEwLjc5M0M3LjUxODQgMTAuNjEwOCA3LjI2NTggMTAuNTEgNy4wMDM2IDEwLjUxMjNDNi43NDE0IDEwLjUxNDYgNi40OTA1OSAxMC42MTk4IDYuMzA1MTggMTAuODA1MkM2LjExOTc3IDEwLjk5MDYgNi4wMTQ2IDExLjI0MTQgNi4wMTIzMyAxMS41MDM2QzYuMDEwMDUgMTEuNzY1OCA2LjExMDg0IDEyLjAxODQgNi4yOTMgMTIuMjA3TDkuMjkzIDE1LjIwN0M5LjM5MTI2IDE1LjMwNTIgOS41MDg4OSAxNS4zODE4IDkuNjM4NDIgMTUuNDMyMUM5Ljc2Nzk0IDE1LjQ4MjMgOS45MDY1IDE1LjUwNSAxMC4wNDUzIDE1LjQ5ODZDMTAuMTg0IDE1LjQ5MjMgMTAuMzIgMTUuNDU3MiAxMC40NDQ0IDE1LjM5NTRDMTAuNTY4OCAxNS4zMzM3IDEwLjY3OTEgMTUuMjQ2NyAxMC43NjggMTUuMTRMMTUuNzY4IDkuMTRaIiBmaWxsPSIjMDBBNTkwIi8+Cjwvc3ZnPgo=');
          }
        }
        .active{
          color: $dark;
          font-weight: 700;
        }
      }
      .progress-bar{
        background-color: #003F37;
      }
    }
  }

  // CIRCLE
  &.circle{
    $borderWidth: 5px;
    $animationTime: .0s;
    $border-color-default: #efefef;
    $border-color-fill: $color7;
    $size: 90px;
    $howManySteps: 100; //this needs to be even. 
    
    .progress {
      width: $size;
      height: $size;
      line-height: $size;
      background: none;
      margin: 0 auto;
      margin-right: 1rem;
      box-shadow: none;
      position: relative;
      &--labels{
        flex: 1;
        padding-bottom: 1rem;
        border-bottom: 2px solid $color7;
        .h2{
          display: block;
          color: $color1;
          font-size: 26px;
          margin-bottom: 0;
        }
      }
      &:after {
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: $borderWidth solid $border-color-default;
        position: absolute;
        top: 0;
        left: 0;
      }
      > span {
        width: 50%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        z-index: 1;
      }
      .progress-left {
        left: 0;
      }
      .progress-bar {
        width: 100%;
        height: 100%;
        background: none;
        border-width: $borderWidth;
        border-style: solid;
        position: absolute;
        top: 0;
        border-color: $border-color-fill;
      }
      .progress-left .progress-bar {
        left: 100%;
        border-top-right-radius: ($size/2);;
        border-bottom-right-radius: ($size/2);;
        border-left: 0;
        -webkit-transform-origin: center left;
        transform-origin: center left;
        //animation: loading-2 1.5s linear forwards 1.8s;
      }
      .progress-right {
        right: 0;
        .progress-bar {
          left: -100%;
          border-top-left-radius: ($size/2);;
          border-bottom-left-radius: ($size/2);;
          border-right: 0;
          -webkit-transform-origin: center right;
          transform-origin: center right;
          //animation: loading-1 1.8s linear forwards;
        }
      }
      .progress-value {
        display: flex;
        border-radius: 50%;
        font-size: 1rem;
        text-align: center;
        line-height: 20px;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        font-weight: 700;
        font-size: 1.2rem;
        color: $dark; 
        span {
          font-size: 12px;
          text-transform: uppercase;
        }
      }
    }
    
    /* This for loop creates the necessary css animation names 
    Due to the split circle of progress-left and progress right, we must use the animations on each side. 
    */
    @for $i from 1 through $howManySteps {
      $stepName: ($i*(100 / $howManySteps));
    
      //animation only the left side if below 50%
      @if $i <= ($howManySteps/2) { 
        .progress[data-percentage="#{$stepName}"] {
          .progress-right .progress-bar {
             animation: loading-#{$i} $animationTime linear forwards;
          }
          .progress-left .progress-bar {animation: 0;}
        }
      }
      //animation only the right side if above 50%
      @if $i > ($howManySteps/2)  {  
        .progress[data-percentage="#{$stepName}"] {
          .progress-right .progress-bar {
            animation: loading-#{($howManySteps/2)} $animationTime linear forwards; //set the animation to longest animation
          }
          .progress-left .progress-bar {
          animation: loading-#{$i - ($howManySteps/2)} $animationTime linear forwards $animationTime;
        }
        }
      }
    }
    
    //animation
    @for $i from 1 through ($howManySteps/2) { 
      $degrees: (180/($howManySteps/2));
      $degrees: ($degrees*$i);
      @keyframes loading-#{$i}{
        0%{
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100%{
            -webkit-transform: rotate($degrees);
          transform: rotate(#{$degrees}deg);
        }
      }
    }
  }
}
