body{
    font-family: 'Lato', sans-serif;
    font-weight: 400; // 400, 700
    // font-style: normal;
    font-size: 16px;
    // font-size: 1.1rem;
    color: $color2;
}
h1, h2, h3, h4, h5, h6{ 
    font-weight: 500;
    color: $color1;
    
}
h1{ font-size: 34px;margin-bottom: 1.6rem;}
h2{ font-size: 24px;margin-bottom: 1.3rem;}
h3{ font-size: 20px;}
a{
    color: $color3;
    text-decoration: underline;
    &:hover{
        color: $color1;
        text-decoration: underline;
    }
    &.text-muted{
        color:$gray-500;
        font-size: 0.95rem;
    }
}
.lead{
    font-size: 1.1rem;
}

// CUSTOM UL WITH CHECK MARKS
.list-item-checkmark {
    padding: 0;
    li {
        display: flex;
        margin-bottom: 0.5rem;
        &:before {
            content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAOCAYAAAAi2ky3AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD9SURBVHgBpZLJjcJAEEVfW8ydDAYysAczZxwJzgQTCSISCMASZOB2BHBnaaq8gEEGhPlSS73p1fYNXyjD7xt+Nrr3+EICWYEbwLk7KGe0EIgvyzpOUY8OyglnDmKB7BUyZGv5VAqxhE6X9Mini3L+pzfIOG6+Gcs4043jEEiK+2cQjW7obcq/zIekSfPd045r5+XT6gXk+t4GKUDaLO287P1yEm0QHTN9h1u2QYrSGhE1bTEYyS/pvLqvDKdeYTsgDXiiwkc6PoeJqtQTnUwZpTaceuUY8ULmvoy/2ODV5a1lTWrDvfOKebzICBO5nJWnwnBBJ8PVMMto94nhLrcxbKkny9GCAAAAAElFTkSuQmCC');
            vertical-align: middle;
            margin-right: 0.2rem;
            margin-top: 0.2rem;
            line-height: 0;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }

}

// CUSTOM BREADCRUMBS
.breadcrumb{
    border-top: 1px solid $gray-300;
    border-bottom: 1px solid $gray-300;
    background-color: transparent;
    margin-bottom: 3rem;
    padding-left: 0;
    padding-right: 0;
    ul{
        display: inline-flex;
        margin: 0;
        padding: 0;
        list-style: none;
        a{
            &:after{
                content: '/';
                display: inline-block;
                margin: 0 .5rem;
                height: 10px;
                width: 10px;
            }
            &:hover{
                text-decoration: underline;
            }
        }
    }
    @include media-breakpoint-only(xs) {
        display: none;
    }
}

