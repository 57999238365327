.questions{
    counter-reset: css-counter 0; /* initializes counter to 0; use -1 for zero-based numbering */
    &--row{
        counter-increment: css-counter 1; /* Increase the counter by 1. */
        display: flex;
        flex-wrap: nowrap;
        gap: 2rem;
        border-top: 1px solid #E2E8F0;
        &.bottomline{
            border-bottom: 1px solid #E2E8F0;
        }
        &:before{
            width: 0;
            content: counter(css-counter) ". ";
            padding-top: .35rem;
            padding-bottom: .35rem;
            font-weight: 700;
            color: $color1;
        }        
        &.error{
            &:before,
            .questions--row-title{
                color: #E07079;
            }

        }
        > div{
            justify-content: center;
        }
    }
    &--row-question{
        display: flex;
        flex-direction: column;
        flex: 1;
        // width: 60%;
        // justify-content: flex-start !important;
        justify-content: center;
        padding-top: .35rem;
        padding-bottom: .35rem;
    }
    &--row-title{
        font-weight: 700;
        color: $color1;
        display: flex;
        align-items: center;
    }
    &--row-answer{
        display: flex;
        flex-wrap: nowrap;
        width: 40%;
        > div{
            flex: 1;
        }

        > div:nth-child(odd) input[type=radio] + label{
            background-color: $color6;
        }
        > div:nth-child(even) input[type=radio] + label{
            background-color: $white;
        }
        input[type=radio] {
            display:none;
        }
        input[type=radio] + label {
            justify-content: center;
            height: 100%;
            display: flex;
            align-items: center;
            padding: 0.4rem 1.0rem;
            white-space: nowrap;
            font-weight: 400;
            color: $color2;
            &:hover{
                cursor: pointer;
                // background-color: rgba($color7, .3) !important;
                color: $color7 ;
            }
            &:focus{
                cursor: pointer;
                background-color: $color7 !important;
                // color: white !important;
            }
        }
        input:checked + label {
            background-color: $color7 !important ;
            color: $white !important;
            &:hover{
                background-color: $color7 !important;
            }
        }
        textarea{
            background-color: $color6 !important;
        }
    }
    @include media-breakpoint-only(lg) {
        &--row-answer{
            width: 60%;
        }
        &--row{
            &.tevredenheid{
                // outline: 3px solid red;
                flex-wrap: wrap;
                gap:0;
                .questions--row-question{ 
                    margin-left: 2rem;
                }
                .questions--row-answer{
                    // outline: 3px solid blue;
                    flex-basis: 100%; 
                    margin-left: 2rem;
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        &--row{
            flex-wrap: wrap;
            border-radius: 9px;
            box-shadow: 0 0 10px rgba($gray-600, 0.25);
            padding: 0;
            margin-bottom: 1.5rem;
            gap: 0.5rem;
            &:before{
                width: 32px;
                padding-top: 0.85rem;
                padding-left: 0.85rem;
            }
            &.noborder{
                box-shadow: none;
                border:0;
                &:before{
                    width: calc(32px - 0.85rem);
                    padding-left: 0;
                    padding-top: 0;
                }
                .questions--row-question{
                    padding-top: 0;
                }
            }
            &.tevredenheid{
                flex-wrap: wrap;
                .questions--row-question{                 
                    justify-content: flex-start !important;
                }
                .questions--row-answer{
                    flex-basis: 100%; 
                    margin-left: 1.6rem;
                }
            }
        }
        &--row-question{
            flex-basis: calc(100% - 50px);
            padding: 0.85rem 1rem 0  0;
        }
        &--row-answer{
            flex-basis: 100%;
            > div:nth-child(odd) input[type=radio] + label{
                background-color: #F1F5F9;
            }
            > div:nth-child(even) input[type=radio] + label{
                background-color: #F8FAFC;
            }
            > div{
                flex-grow: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
            }
            input[type=radio] + label {
                width: 100%;
                display: inherit;
                justify-content: center;
                padding: 0.8rem 0.4rem;
                font-size: 0.85rem;
                line-height: 115%;
                white-space: inherit;
                margin-bottom: 0;
            }
        }
    }
    @include media-breakpoint-only(xs) {
        &--row-question{
            justify-content: flex-start !important;
        }
        &--row{
            &.tevredenheid{
                .questions--row-question{
                    display: block;
                }
                & div{
                    display: block;
                    width: 100%;
                    text-align: left;
                    input[type=radio]+label{
                        display: inline-block;
                        padding-left: 1.8rem;
                    }
                }
            }
        }
    }
}